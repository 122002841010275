import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileReader = _resolveComponent("FileReader")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.language === 'en')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_FileReader, {
            "file-id": _ctx.submenuName + '.en'
          }, null, 8, ["file-id"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.language === 'es')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_FileReader, {
            "file-id": _ctx.submenuName + '.es'
          }, null, 8, ["file-id"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
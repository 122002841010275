

import { defineComponent } from "vue";
import { mapActions } from "pinia";
import { useLanguage } from "@/stores/language";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup(){
    const languageStore= useLanguage();
    return {languageStore}
  },
  methods: {
    async setDefaultLanguage(){
      const i18next = useI18n()
      console.log(i18next.locale.value)
      if (i18next.locale.value.includes("en")){
        this.setLanguage("en")
      } else {
        this.setLanguage("es")
      }
    },
    ...mapActions(useLanguage, ['setLanguage']),
  },
  async created() {
    await this.setDefaultLanguage();
  }
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileReader = _resolveComponent("FileReader")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FileReader, {
      "file-id": (_ctx.$route.params.fileId as string)
    }, null, 8, ["file-id"])
  ]))
}
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b689ab9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-list" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "card-text" }
const _hoisted_7 = { class: "card-footer" }
const _hoisted_8 = {
  key: 1,
  class: "small-container"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "card-title" }
const _hoisted_12 = { class: "card-text" }
const _hoisted_13 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_MasonryWall = _resolveComponent("MasonryWall")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.innerWidth > _ctx.heightLimit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_MasonryWall, {
            items: _ctx.articles,
            gap: 0
          }, {
            default: _withCtx(({ item, index }) => [
              _createVNode(_component_RouterLink, {
                to: '/posts/' + item.id,
                "column-width": 100,
                class: "card post-card",
                style: {"width":"18rem"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: item.image,
                    class: "card-img-top",
                    alt: "..."
                  }, null, 8, _hoisted_3),
                  _createElementVNode("div", {
                    href: index,
                    class: "card-body"
                  }, [
                    _createElementVNode("h5", _hoisted_5, _toDisplayString(item.title), 1),
                    _createElementVNode("p", _hoisted_6, _toDisplayString(item.description), 1)
                  ], 8, _hoisted_4),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.parseDate(item.dateParsed)), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          }, 8, ["items"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.innerWidth < _ctx.heightLimit || _ctx.innerWidth == _ctx.heightLimit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (item) => {
            return (_openBlock(), _createBlock(_component_RouterLink, {
              to: '/posts/' + item.id,
              key: item.id,
              "column-width": 100,
              class: "card small-container"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: item.image,
                  class: "card-img-top",
                  alt: "..."
                }, null, 8, _hoisted_9),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("h5", _hoisted_11, _toDisplayString(item.title), 1),
                  _createElementVNode("p", _hoisted_12, _toDisplayString(item.description), 1)
                ]),
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.parseDate(item.dateParsed)), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
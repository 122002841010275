
/* eslint-disable */
import PostsList from '@/components/PostsList.vue'; // @ is an alias to /src

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  components:{PostsList},
});

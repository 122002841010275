
  import { mapActions } from "pinia";
  import { useLanguage } from "@/stores/language";
  import { useI18n } from "vue-i18n";
  import { defineComponent, watch } from "vue";
  export default defineComponent({
    name: 'NavbarTop',
    setup(){
      const i18n = useI18n();
      const languageStorage = useLanguage();
       return {i18n, languageStorage}
    },
    data() {
      return {
        language:"es",
        selected:"es"
      };
    },
    methods: {
      languageSelected(event: any){
        this.language = event.target.value
        console.log(this.language)
        this.setLanguage(this.language)
        this.$i18n.locale = this.language
      },
      ...mapActions(useLanguage, ['setLanguage']),
    },
  
    created() {
        const locale = this.i18n.locale
        console.log(locale)
        if (locale.value == "es") {
          this.language = "es"
          this.setLanguage("es")
          this.selected = "es"
        } else {
          this.language = "en"
          this.setLanguage("en")
          this.selected = "en"
        }

        const languageStorage = useLanguage();
        languageStorage.$onAction(({name:useLanguage, args}:any)=>{
          const url = args[0]      
          this.language = url
        }, true)
    },
    updated() {
      //this.getSources();
    },
  });
  